import { formatGwei } from '../utils/unit/formatGwei.js';
import { BaseError } from './base.js';
export class ExecutionRevertedError extends BaseError {
  constructor({
    cause,
    message
  } = {}) {
    const reason = message?.replace('execution reverted: ', '')?.replace('execution reverted', '');
    super(`Execution reverted ${reason ? `with reason: ${reason}` : 'for an unknown reason'}.`, {
      cause,
      name: 'ExecutionRevertedError'
    });
  }
}
Object.defineProperty(ExecutionRevertedError, "code", {
  enumerable: true,
  configurable: true,
  writable: true,
  value: 3
});
Object.defineProperty(ExecutionRevertedError, "nodeMessage", {
  enumerable: true,
  configurable: true,
  writable: true,
  value: /execution reverted/
});
export class FeeCapTooHighError extends BaseError {
  constructor({
    cause,
    maxFeePerGas
  } = {}) {
    super(`The fee cap (\`maxFeePerGas\`${maxFeePerGas ? ` = ${formatGwei(maxFeePerGas)} gwei` : ''}) cannot be higher than the maximum allowed value (2^256-1).`, {
      cause,
      name: 'FeeCapTooHighError'
    });
  }
}
Object.defineProperty(FeeCapTooHighError, "nodeMessage", {
  enumerable: true,
  configurable: true,
  writable: true,
  value: /max fee per gas higher than 2\^256-1|fee cap higher than 2\^256-1/
});
export class FeeCapTooLowError extends BaseError {
  constructor({
    cause,
    maxFeePerGas
  } = {}) {
    super(`The fee cap (\`maxFeePerGas\`${maxFeePerGas ? ` = ${formatGwei(maxFeePerGas)}` : ''} gwei) cannot be lower than the block base fee.`, {
      cause,
      name: 'FeeCapTooLowError'
    });
  }
}
Object.defineProperty(FeeCapTooLowError, "nodeMessage", {
  enumerable: true,
  configurable: true,
  writable: true,
  value: /max fee per gas less than block base fee|fee cap less than block base fee|transaction is outdated/
});
export class NonceTooHighError extends BaseError {
  constructor({
    cause,
    nonce
  } = {}) {
    super(`Nonce provided for the transaction ${nonce ? `(${nonce}) ` : ''}is higher than the next one expected.`, {
      cause,
      name: 'NonceTooHighError'
    });
  }
}
Object.defineProperty(NonceTooHighError, "nodeMessage", {
  enumerable: true,
  configurable: true,
  writable: true,
  value: /nonce too high/
});
export class NonceTooLowError extends BaseError {
  constructor({
    cause,
    nonce
  } = {}) {
    super([`Nonce provided for the transaction ${nonce ? `(${nonce}) ` : ''}is lower than the current nonce of the account.`, 'Try increasing the nonce or find the latest nonce with `getTransactionCount`.'].join('\n'), {
      cause,
      name: 'NonceTooLowError'
    });
  }
}
Object.defineProperty(NonceTooLowError, "nodeMessage", {
  enumerable: true,
  configurable: true,
  writable: true,
  value: /nonce too low|transaction already imported|already known/
});
export class NonceMaxValueError extends BaseError {
  constructor({
    cause,
    nonce
  } = {}) {
    super(`Nonce provided for the transaction ${nonce ? `(${nonce}) ` : ''}exceeds the maximum allowed nonce.`, {
      cause,
      name: 'NonceMaxValueError'
    });
  }
}
Object.defineProperty(NonceMaxValueError, "nodeMessage", {
  enumerable: true,
  configurable: true,
  writable: true,
  value: /nonce has max value/
});
export class InsufficientFundsError extends BaseError {
  constructor({
    cause
  } = {}) {
    super(['The total cost (gas * gas fee + value) of executing this transaction exceeds the balance of the account.'].join('\n'), {
      cause,
      metaMessages: ['This error could arise when the account does not have enough funds to:', ' - pay for the total gas fee,', ' - pay for the value to send.', ' ', 'The cost of the transaction is calculated as `gas * gas fee + value`, where:', ' - `gas` is the amount of gas needed for transaction to execute,', ' - `gas fee` is the gas fee,', ' - `value` is the amount of ether to send to the recipient.'],
      name: 'InsufficientFundsError'
    });
  }
}
Object.defineProperty(InsufficientFundsError, "nodeMessage", {
  enumerable: true,
  configurable: true,
  writable: true,
  value: /insufficient funds|exceeds transaction sender account balance/
});
export class IntrinsicGasTooHighError extends BaseError {
  constructor({
    cause,
    gas
  } = {}) {
    super(`The amount of gas ${gas ? `(${gas}) ` : ''}provided for the transaction exceeds the limit allowed for the block.`, {
      cause,
      name: 'IntrinsicGasTooHighError'
    });
  }
}
Object.defineProperty(IntrinsicGasTooHighError, "nodeMessage", {
  enumerable: true,
  configurable: true,
  writable: true,
  value: /intrinsic gas too high|gas limit reached/
});
export class IntrinsicGasTooLowError extends BaseError {
  constructor({
    cause,
    gas
  } = {}) {
    super(`The amount of gas ${gas ? `(${gas}) ` : ''}provided for the transaction is too low.`, {
      cause,
      name: 'IntrinsicGasTooLowError'
    });
  }
}
Object.defineProperty(IntrinsicGasTooLowError, "nodeMessage", {
  enumerable: true,
  configurable: true,
  writable: true,
  value: /intrinsic gas too low/
});
export class TransactionTypeNotSupportedError extends BaseError {
  constructor({
    cause
  }) {
    super('The transaction type is not supported for this chain.', {
      cause,
      name: 'TransactionTypeNotSupportedError'
    });
  }
}
Object.defineProperty(TransactionTypeNotSupportedError, "nodeMessage", {
  enumerable: true,
  configurable: true,
  writable: true,
  value: /transaction type not valid/
});
export class TipAboveFeeCapError extends BaseError {
  constructor({
    cause,
    maxPriorityFeePerGas,
    maxFeePerGas
  } = {}) {
    super([`The provided tip (\`maxPriorityFeePerGas\`${maxPriorityFeePerGas ? ` = ${formatGwei(maxPriorityFeePerGas)} gwei` : ''}) cannot be higher than the fee cap (\`maxFeePerGas\`${maxFeePerGas ? ` = ${formatGwei(maxFeePerGas)} gwei` : ''}).`].join('\n'), {
      cause,
      name: 'TipAboveFeeCapError'
    });
  }
}
Object.defineProperty(TipAboveFeeCapError, "nodeMessage", {
  enumerable: true,
  configurable: true,
  writable: true,
  value: /max priority fee per gas higher than max fee per gas|tip higher than fee cap/
});
export class UnknownNodeError extends BaseError {
  constructor({
    cause
  }) {
    super(`An error occurred while executing: ${cause?.shortMessage}`, {
      cause,
      name: 'UnknownNodeError'
    });
  }
}
